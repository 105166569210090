<template>
  <v-container class="pa-0">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>

    <form novalidate>
      <op-confirmation-dialog ref="confirm" :showButton="showButton" />
      <div v-if="loaded">
        <v-card elevation="0" outlined color="#fafafa">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  :label="$t('view.companies.simulation_name')"
                  v-model="localSimulation.name"
                  class="required"
                >
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="localSimulation.campaign_id"
                  :items="campaigns"
                  item-text="name"
                  item-value="id"
                  filled
                  :label="$t('view.companies.select_campaign')"
                  class="required"
                  :disabled="localSimulation.is_active"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="selectedEmployeeGroupIds"
                  :items="employeeGroups"
                  :disabled="
                    localSimulation.is_active || localSimulation.is_stopped
                  "
                  multiple
                  item-text="name"
                  item-value="id"
                  filled
                  :label="$t('view.companies.select_employee_group')"
                  class="required"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-dialog v-model="showModalExercice" max-width="80%">
                <v-card>
                  <v-card-title>
                    <div style="width: 100%; display: inline-block">
                      {{ $t("view.companies.select_exercise") }}
                    </div>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          :label="$t('view.companies.search')"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          :items="availableLangArray"
                          v-model="selectedLanguage"
                          item-text="label"
                          item-value="code"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <div v-if="noExercisesFound" class="text-center my-4">
                        {{ $t("view.companies.no_exercises_found") }}
                      </div>
                      <div v-else class="d-flex flex-wrap justify-space-around">
                        <div
                          v-for="exercise in filteredExercises"
                          :key="exercise.id"
                          class="ma-2 position-relative"
                          style="min-width: 220px; max-width: 20%"
                        >
                          <v-card
                            style="width: 100%"
                            @click="selectExercise(exercise)"
                            class="exercise-card"
                          >
                            <div
                              class="position-absolute icon-background"
                              style="top: 0; left: 0; z-index: 1"
                            >
                            </div>
                            <v-img
                              :src="
                                exercise.illustration_url
                                  ? exercise.illustration_url
                                  : require('@/assets/exercice-image.png')
                              "
                              aspect-ratio="1"
                              max-height="150"
                            ></v-img>

                            <div class="title-card">
                              {{ exercise.title }}
                            </div>
                            <v-divider class="mx-4"></v-divider>
                            <div class="subtitle">
                              <span class="label">{{ $t("Theme") }}:</span>
                              {{ exercise.theme }}
                            </div>
                            <div class="subtitle">
                              <span class="label"
                                >{{ $t("Difficulty") }} :</span
                              >
                              {{ exercise.difficult ? exercise.level : "-" }}
                            </div>
                            <div class="subtitle">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <div
                                    v-on="on"
                                    style="
                                      display: inline-block;
                                      position: relative;
                                      z-index: 9999;
                                    "
                                  >
                                    <Language-flag-component
                                      :name="exercise.lang"
                                    ></Language-flag-component>
                                  </div>
                                </template>
                                <span style="z-index: 9999">{{
                                  getLanguageName(exercise.lang)
                                }}</span>
                              </v-tooltip>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      text
                      @click="showModalExercice = false"
                      >{{ $t("view.button.close") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-col cols="4">
                <v-select
                  v-model="localSimulation.is_test"
                  :items="[false, true]"
                  filled
                  :label="$t('view.companies.simulation_type')"
                  class="required"
                  :disabled="localSimulation.is_active"
                >
                  <template #selection="{ item }">
                    {{ displayLabel(item) }}
                  </template>
                  <template #item="{ item }">
                    {{ displayLabel(item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :disabled="localSimulation.is_active"
                  v-model="selectedExercise.title"
                  filled
                  :label="$t('view.companies.select_exercise')"
                  readonly
                  @click="showModalExercice = true"
                >
                  <template #append>
                    <v-icon @click="showModalExercice = true"
                      >mdi-menu-down
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>

              <v-col align="center" class="pa-5">
                <v-btn
                  color="blue"
                  @click="customTagsDialog = true"
                  :disabled="!showCustomizeButton"
                  class="text-none rounded-lg"
                  large
                  dark
                >
                  {{ $t("view.companies.customize") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="localSimulation.phishing_exercise_id">
              <v-dialog
                content-class="dial"
                persistent
                eager
                v-model="customTagsDialog"
                :max-width="1180"
                :min-height="1200"
              >
                <v-card>
                  <v-row>
                    <v-col>
                      <phishing-template-edition
                        ref="myTemplateTagsForm"
                        :templateTags="exerciseTemplateTags"
                        :exerciseId="localSimulation.phishing_exercise_id"
                        :companyUuid="companyUuid"
                        @close="customTagsDialog = false"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </v-row>

            <v-row>
              <v-col cols="6">
                <op-datepicker
                  ref="datepicker"
                  :label="$t('Start date of shipments')"
                  :date="localSimulation.launch_date"
                  v-on:update="updateLaunchDate"
                  class="required"
                  :simulationIsActive="localSimulation.is_active"
                >
                </op-datepicker>
              </v-col>
              <v-col cols="6">
                <op-datepicker
                  :label="$t('End date of shipments')"
                  :date="localSimulation.send_by_date"
                  v-on:update="updateSendByDate"
                  min="localSimulation.launch_date"
                  :simulationIsActive="localSimulation.is_active"
                >
                </op-datepicker>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-time-picker
                  color="green lighten-1"
                  format="24hr"
                  v-model="localSimulation.launch_time"
                  :readonly="localSimulation.is_active"
                ></v-time-picker>
              </v-col>
              <v-col cols="6">
                <v-time-picker
                  v-model="localSimulation.send_by_time"
                  format="24hr"
                  :readonly="localSimulation.is_active"
                ></v-time-picker>
              </v-col>
            </v-row>

            <div class="mt-6 text-h6">
              <v-icon class="mr-2">mdi-settings</v-icon>
              {{ $t("view.companies.automation") }}
            </div>
            <v-divider class="my-2"></v-divider>

            <v-row>
              <v-col class="my-0 py-0" cols="6">
                <v-switch
                  v-model="localSimulation.has_automatic_launch"
                  :disabled="localSimulation.is_active"
                  :label="$t('Automatic start-up')"
                  color="green"
                ></v-switch>
              </v-col>
            </v-row>
            <!-- Automatic Launch message -->
            <v-row class="ma-0 pa-0">
              <div v-if="localSimulation.has_automatic_launch">
                <div v-if="errorMessage">
                  <span class="subtitle">{{
                    $t(
                      "view.companies.it_will_not_possible_to_meet_the_deadline"
                    )
                  }}</span>
                </div>
                <div v-else>
                  <span class="subtitle"
                    >{{
                      $t("view.companies.last_date_to_change_launch_date")
                    }}
                    :</span
                  ><span
                    ><i>{{ formatDeadLineDate(localSimulation) }}</i></span
                  >
                </div>
              </div>
            </v-row>
            <v-row>
              <v-col class="my-0 py-0" cols="6">
                <v-switch
                  v-model="localSimulation.has_automatic_stop"
                  :label="$t('view.companies.simulation_automatic_stop')"
                  color="green"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn elevation="2" @click="visualizeCompiledExercise()" class="text-none rounded-lg"
              >{{ $t("view.button.view_template") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="2"
              @click.prevent="submit()"
              dark
              color="green"
              class="text-none rounded-lg"

              >{{ $t("view.button.save") }}
            </v-btn>
            <v-btn elevation="2" @click="close()" class="text-none rounded-lg"
>{{
              $t("view.button.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </form>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import programsMixin from "@/mixins/programs.mixin"
import phishingMixin from "@/mixins/phishing.mixin"
import employeesMixin from "@/mixins/employees.mixin"
import campaignsMixin from "@/mixins/campaigns.mixin"
import companiesPhishingMixin from "@/mixins/companies.phishing.mixin"
import moment from "moment"
import "moment/locale/fr"
import { i18n } from "@/plugins/i18n"
import monitoringMixins from "@/mixins/monitoring.mixins.js"

moment.locale("fr")

export default {
  mixins: [
    programsMixin,
    companiesPhishingMixin,
    employeesMixin,
    campaignsMixin,
    phishingMixin,
    monitoringMixins,
  ],
  name: "phishing-simulation-edition",
  props: {
    simulation: {
      default: null,
    },
    campaignId: {
      default: null,
    },
  },
  data: function () {
    return {
      snackbar: false,
      snackbarText: null,
      errorMessage: false,
      canSave: false,
      showButton: true,
      customTagsDialog: false,
      localSimulation: null,
      selectedEmployeeGroupIds: [],
      campaign: null,
      loaded: false,
      exerciseTemplateTags: null,
      exercises: [],
      sendingProfiles: [],
      sendDate: null,
      dateNull: null,
      showModalExercice: false,
      selectedExercise: {},
      campaigns: null,
      search: "",
      monitoringRecord: null,
      selectedLanguage: "",
      langArray: [
        { code: "", label: i18n.t("view.learning_phishing.all_languages") },
        { code: "de", label: "Deutsch" },
        { code: "nl", label: "Dutch" },
        { code: "en", label: "English" },
        { code: "es", label: "Español" },
        { code: "fr", label: "Français" },
        { code: "it", label: "Italiano" },
        { code: "pl", label: "Polska" },
        { code: "pt", label: "Português" },
        { code: "ro", label: "Română" },
        { code: "sv", label: "Svenska" },
        { code: "tr", label: "Türkçe" },
      ],
    }
  },

  async mounted() {
    //TODO program, campaigns, employee-groups, exercises and sending profiles should be received in props
    this.campaigns = await this.getAllCampaigns()

    this.exercises = await this.getCompanyPhishingEntities("exercises")

    this.sendingProfiles = await this.getCompanyPhishingEntities(
      "sending-profiles"
    )
    this.employeeGroups = await this.getAllEmployeeGroups()

    this.selectedEmployeeGroupIds = []

    if (this.simulation && this.simulation.employee_groups) {
      for (const group of this.simulation.employee_groups) {
        this.selectedEmployeeGroupIds.push(group.id)
      }
    }

    this.localSimulation = JSON.parse(JSON.stringify(this.simulation))

    if (this.campaignId) {
      const campaign = this.campaigns.find((c) => c.id === this.campaignId)
      this.localSimulation.campaign_id = campaign ? campaign.id : null
    }

    this.updateAutomaticLaunchMessage()
    this.updateCampaignFromCampaignId()

    if (this.simulation && this.simulation.phishing_exercise_id) {
      await this.initExerciseTags()
      this.updateSelectedExercice(this.localSimulation.phishing_exercise_id)
    }

    //boucle pour afficher le status de chaque serveur
    //for (let i = 0; i < this.exercises.length; i++) {
    //const exercise = this.exercises[i]
    //this.monitoringRecord = await this.getServerStatus(
    //  exercise.phishing_server_id
    //)
    //if (this.monitoringRecord) {
    // exercise.status = this.monitoringRecord.status
    // }
    //}

    this.loaded = true

    // what is this ???
    this.$emit("loaded")
  },
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
    form() {
      return this.$refs.form
    },
    filteredExercises() {
      return this.exercises
        .filter((exercise) => {
          const matchesSearch =
            exercise.title.toLowerCase().includes(this.search.toLowerCase()) ||
            (exercise.theme &&
              exercise.theme.toLowerCase().includes(this.search.toLowerCase()))

          const matchesLanguage =
            this.selectedLanguage === ""
              ? true
              : exercise.lang === this.selectedLanguage

          return matchesSearch && matchesLanguage
        })
        .sort((a, b) => a.title.localeCompare(b.title))
    },

    // Function to calculate the available languages based on existing exercises
    availableLangArray() {
      const availableLangs = this.exercises.reduce((acc, exercise) => {
        if (!acc.includes(exercise.lang)) {
          acc.push(exercise.lang)
        }
        return acc
      }, [])

      return this.langArray.filter(
        (lang) => lang.code === "" || availableLangs.includes(lang.code)
      )
    },

    // To display a message to the user when the search returns no results
    noExercisesFound() {
      return this.filteredExercises.length === 0
    },
    showCustomizeButton() {
      // Customize the logic based on your requirements
      return !!this.exerciseTemplateTags && !this.localSimulation.is_active
    },
  },
  methods: {
    getStatus(exercise) {
      const statusMap = {
        up: { icon: "mdi-check-circle", color: "green" },
        down: {
          icon: "mdi-close-circle",
          color: "red",
        },
        unknown: { icon: "mdi-alert-circle-outline", color: "grey darken-3" },
      }

      return statusMap[exercise.status] || statusMap.unknown
    },
    displayLabel(value) {
      return value ? i18n.t("Test") : i18n.t("Normal")
    },

    /**
     * Compare default exercise tags with simulation tags,
     * and use default tags if simulation tags fields are the same as the default ones
     */
    async initExerciseTags() {
      const defaultTags = await this.getCompanyExerciseTemplateTags(
        this.simulation.phishing_exercise_id
      )

      const currentTags = JSON.parse(this.simulation["template_tags"])

      if (
        defaultTags &&
        currentTags &&
        this.isTagStructureIdentical(defaultTags, currentTags)
      ) {
        this.exerciseTemplateTags = JSON.parse(JSON.stringify(currentTags))
      } else {
        console.error("Tags structure is not identical to default one")
        this.exerciseTemplateTags = JSON.parse(JSON.stringify(defaultTags))
      }
    },

    isTagStructureIdentical(defaultTags, currentTags) {
      let keyTags = []
      let keyCurrentTags = []

      if (defaultTags.email) {
        keyTags = Object.keys(defaultTags.email.tags)
      }

      if (defaultTags.landingPage) {
        keyTags = keyTags.concat(Object.keys(defaultTags.landingPage.tags))
      }

      if (currentTags.email) {
        keyCurrentTags = Object.keys(currentTags.email.tags)
      }

      if (currentTags.landingPage) {
        keyCurrentTags = keyCurrentTags.concat(
          Object.keys(currentTags.landingPage.tags)
        )
      }

      const sortedTags = keyTags.slice().sort()
      const sortedCurrentTags = keyCurrentTags.slice().sort()

      return (
        sortedTags.length === sortedCurrentTags.length &&
        sortedTags
          .slice()
          .sort()
          .every(function (value, index) {
            return value === sortedCurrentTags[index]
          })
      )
    },

    async displayTemplates(exerciseId) {
      this.exerciseTemplateTags = null
      const tags = await this.getCompanyExerciseTemplateTags(exerciseId)
      this.exerciseTemplateTags = JSON.parse(JSON.stringify(tags))
    },

    updateCampaignFromCampaignId() {
      if (!this.localSimulation.campaign_id) {
        this.campaign = null
        this.program = null
        return
      }

      this.campaign = this.campaigns.find(
        (c) => c.id === this.localSimulation.campaign_id
      )

      if (!this.campaign) {
        this.localSimulation.campaign_id = null
      }
    },

    async submit() {
      this.showButton = true

      if (
        !this.selectedEmployeeGroupIds ||
        this.selectedEmployeeGroupIds.length <= 0
      ) {
        this.snackbar = true
        this.snackbarText = i18n.t("view.companies.select_employee_group")
        return
      }

      if (!this.localSimulation.has_automatic_launch) {
        this.localSimulation.has_automatic_launch = false
      }

      if (!this.localSimulation.has_automatic_stop) {
        this.localSimulation.has_automatic_stop = false
      }

      if (this.exerciseTemplateTags && this.$refs.myTemplateTagsForm) {
        this.localSimulation["template_tags"] =
          this.$refs.myTemplateTagsForm.getTags()
      } else {
        this.localSimulation["template_tags"] = null
      }

      this.localSimulation["employee_group_ids"] = this.selectedEmployeeGroupIds

      if (this.sendDate && !this.canSave) {
        this.showButton = false
        if (
          await this.$refs.confirm.open(
            i18n.t("view.companies.becareful"),
            i18n.t("view.companies.enter_end_shipment_date_message"),
            i18n.t("view.custom_editor.modify")
          )
        ) {
          this.canSave = false
        }
      } else {
        this.$emit("save", this.localSimulation)
      }
    },

    close() {
      this.$emit("close")
    },

    async visualizeCompiledExercise() {
      if (!this.localSimulation.phishing_exercise_id) {
        console.error("no exercise selected")
        return
      }

      if (!this.$refs.myTemplateTagsForm) {
        console.error("no template tags form")
        return
      }

      const exerciseId = this.localSimulation.phishing_exercise_id
      const templateTags = this.$refs.myTemplateTagsForm.getTags()

      const templates = await this.getCompanyExerciseCompiledWithTemplateTags(
        exerciseId,
        templateTags
      )
      this.$emit("view", templates)
    },

    async updateLaunchDate(data) {
      this.showButton = true

      this.localSimulation.launch_date = data
      this.updateAutomaticLaunchMessage()
      const launchDate = new Date(this.localSimulation.launch_date)

      const isOk = launchDate <= new Date()
      if (isOk) {
        if (
          await this.$refs.confirm.open(
            i18n.t("view.companies.confirm_start_date_shipment"),
            i18n.t("view.companies.confirm_start_date_shipment_message"),
            i18n.t("view.custom_editor.modify")
          )
        ) {
          this.canSave = true
        }
      }
    },

    async updateSendByDate(data) {
      this.localSimulation.send_by_date = data
      this.sendDate = new Date(this.localSimulation.send_by_date)
      this.dateNull = this.localSimulation.send_by_date
      const launchDate = new Date(this.localSimulation.launch_date)
      if (this.dateNull != null && this.sendDate < launchDate) {
        this.showButton = false
        this.canSave = false
        if (
          await this.$refs.confirm.open(
            i18n.t("view.companies.becareful"),
            i18n.t("view.companies.becareful_message"),
            i18n.t("view.custom_editor.modify")
          )
        ) {
          this.canSave = false
        }
      } else {
        this.canSave = true
      }
    },

    // Fonction qui va gérer le message en dessous du switch 'Démarrage automatique', si la date d'envoi est incorrecte.
    updateAutomaticLaunchMessage() {
      const launchDate = moment(this.localSimulation.launch_date)
      const correctDate = moment().add(1, "day")
      if (launchDate.isBefore(correctDate)) {
        this.errorMessage = true
      } else {
        this.errorMessage = false
      }
    },

    // Function that formats the deadline for changing the launch date
    formatDeadLineDate(data) {
      const launchDate = moment(data.launch_date)
      launchDate.subtract(1, "day")
      const formattedDate = launchDate.format("dddd D MMMM YYYY")
      return " " + formattedDate + " " + i18n.t("view.companies.at_8AM")
    },

    selectExercise(exercise) {
      this.localSimulation.phishing_exercise_id = exercise.id
      this.selectedExercise = exercise
      this.showModalExercice = false
      this.displayTemplates(exercise.id)
    },

    updateSelectedExercice(exerciseId) {
      this.selectedExercise =
        this.exercises.find((e) => e.id === exerciseId) || {}
    },

    getLanguageName(code) {
      const language = this.langArray.find((lang) => lang.code === code)
      return language ? language.label : code
    },
  },
}
</script>

<style scoped>
.subtitle {
  margin-left: 5px;
  min-width: 150px;
  display: inline-block;
}

.dial {
  overflow-y: hidden !important;
}

.exercise-card {
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title-card {
  height: 33%;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 10px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Permet le retour à la ligne */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limite à deux lignes */
  -webkit-box-orient: vertical;

  padding: 16px;
}

.subtitle {
  font-size: 14px;
  color: #757575;
  margin-top: 4px;
}

.label {
  font-weight: bold;
  margin-right: 4px;
}
.icon-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 0;
}
</style>
